const Technologies = () => {
    const techList = [
        { 'technology': 'ReactJS', 'imgSrc': 'react.png' },
        { 'technology': 'Redux', 'imgSrc': 'redux.png' },
        { 'technology': 'Redux Toolkit', 'imgSrc': 'reduxToolkit.png' },
        { 'technology': 'Angular', 'imgSrc': 'angular.png' },
        { 'technology': 'NodeJS', 'imgSrc': 'nodejs.png' },
        { 'technology': 'JavaScript', 'imgSrc': 'javascript.png' },
        { 'technology': 'TypeScript', 'imgSrc': 'typescript.png' },
        { 'technology': 'MongoDB', 'imgSrc': 'mongodb.png' },
        { 'technology': 'ExpressJS', 'imgSrc': 'express.png' },
        { 'technology': 'NgRx', 'imgSrc': 'ngrx.png' },
        { 'technology': 'HTML5', 'imgSrc': 'html5.png' },
        { 'technology': 'SCSS', 'imgSrc': 'sass.png' },
        { 'technology': 'Bootstrap', 'imgSrc': 'bootstrap.png' },
        { 'technology': 'Azure', 'imgSrc': 'azure.png' },
        { 'technology': 'Visio', 'imgSrc': 'visio.png' },
    ];
    const renderList = () => {
        return techList.map((item, index) => (
            <li id={index}>
                <img src={`/assets/icons/technology/${item.imgSrc}`} alt={item.technology} />
                <div>{item.technology}</div></li>
        ));
    }
    return (
        <ul>
            {renderList()}
        </ul>
    )
}

export default Technologies;