import './footer.scss';

const Footer = () => {
    return (
        <div className="container-fluid footer">
            <div className='row'>
                <div className='col-12'>&copy; 2024 | www.rkrandhir.com</div>    
            </div>
        </div>
    )
}

export default Footer;