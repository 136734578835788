const HomeBanner = () => {
    return (
        <div className="homeBanner container-fluid">
            <div className="row">
                <div className="homeBannerTextWrap col-12">
                    <img src="./assets/images/rkrandhir_logo_white.png" alt="R K Randhir" />
                    {/* <p>Senior Architect - Technology | Cognizant, Noida (India)</p> */}
                    <p>Technical Architect - Blogger - Photographer</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img src="./assets/images/rkrandhir.jpg" alt="R K Randhir" />
                </div>
            </div>
        </div>
    )
}

export default HomeBanner;