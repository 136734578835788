import Footer from "../../Common/Footer/Index";
import HomeBanner from "./Banner";
import './homepage.scss';
import Technologies from "./Technologies";

const Homepage = () => {
    return (
        <>
            <HomeBanner />
            <div className="container-fluid homeMidSection">
                <div className="row">
                    <div className="col-12 col-sm-7 homeIntro">
                        <p>I enjoy venture new dimensions of work, places and people.</p>
                        <p>I have experience in Gen AI Models (GitHub CoPilot), Prompt Engineering, Architecture Design & Development, Full Stack Development & Web technologies. I have a proven prowess in the fields of Architecture design, Technical Solutions, Customer Interaction, business development & strategy.</p>
                        <p>I love being busy with my family, camera and plants, if not doing anything!</p>
                        <ul>
                            <li><a href='https://www.linkedin.com/in/rkrandhir/' class="linkedIn" target="_blank" rel="noopener noreferrer">
                                My LinkedIn Profile</a></li>
                            <li><a href='https://github.com/rkrandhir' class="gitHub" target="_blank" rel="noopener noreferrer">My GitHub Page</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-5 homeTech">
                        <h3>Technologies</h3>
                        <Technologies />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Homepage;