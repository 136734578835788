import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './app.scss';
import Homepage from "./Components/Pages/Homepage";
import AboutMe from "./Components/Pages/Aboutme";
import MyBlogs from "./Components/Pages/Myblogs";
import Contact from "./Components/Pages/Contact";
/* import Header from "./Components/Common/Header/Index";
 */
const App = () => {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" exact Component={Homepage} />
        <Route path="/aboutMe" Component={AboutMe} />
        <Route path="/myBlogs" Component={MyBlogs} />
        <Route path="/contact" Component={Contact} />
      </Routes>
    </Router>
  )
}

export default App;
